<template>
    <div class="pipeline-page-top-bar">
        <div class="d-flex justify-content-between my-5">
            <router-link :to="{ name : 'jobs.published'}" class="back-button m-0">
                <span class="back-button__icon">
                    <i class="eicon e-back"></i>
                </span>
                <div class="back-button__text d-none d-md-block text-capitalize">{{ $t(`Back To Jobs`) }}</div>
                <div v-if="job" class="section-title d-block d-md-none ml-4">{{ job.title }}</div>
            </router-link>

            <div class="d-flex">

                <div class="d-block d-md-none">
                    <div class="d-flex">
                        <a href="#" v-if="canManagementJob" class="custom-topbar-sm-button" @click="editPipeline()">
                            <span class="icon"><i class="eicon e-pencil"></i></span>
                        </a>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="d-flex">
                        <router-link v-if="canViewCandidate" :to="{ name : 'job.candidates', params: { jobId } }" class="button primary-button d-flex ml-3 sm-topbar-button">
                            <span class="icon"><i class="eicon e-users"></i></span>
                            <span> {{ $t(`Candidates`) }} </span>
                        </router-link>

                        <a href="#" v-if="canManagementJob" class="button primary-button d-flex ml-3 sm-topbar-button" @click="editPipeline()">
                            <span class="icon"><i class="eicon e-pencil"></i></span>
                            <span>{{ $t(`Edit Pipeline`) }}</span>
                        </a>
                    </div>
                </div>


                <div class="dropdown pipeline-action ml-3" v-if="hasSelected && canOrganizeCandidate" v-on-click-away="hideMoveToStage">
                    <submit-button type="primary-button dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :click="showStage" :loading="isLoading">
                        <span class="d-none d-md-block">
                            {{ $t(`Move to stage`) }}
                        </span>

                        <span class="d-block d-md-none">
                            {{ $t(`Move to`) }}
                        </span>

                    </submit-button>
                    <div class="dropdown-menu dropdown-menu-right text-break" :class="{ 'show' : openMoveToStage }">
                        <template v-for="pipeline in pipelinesForMove">
                            <a class="dropdown-item" href="#" v-if="!selected(pipeline.id) && !isRemoteInterview(pipeline.id)" @click="moveCandidatesToPipeline(pipeline)" :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }">{{ pipeline.name }}</a>
                            <a class="dropdown-item" href="#" v-if="selected(pipeline.id)" @click="candidateOnboard(pipeline)" :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }">{{ pipeline.name }}</a>
                            <a class="dropdown-item" href="#" v-if="isRemoteInterview(pipeline.id)" @click="selectedForRemoteInterview(pipeline)" :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }">{{ pipeline.name }}</a>
                        </template>
                    </div>
                </div>

            </div>
        </div>

        <modal-onboarding
            v-if="pageState.showOnBoardingModal"
            :applicant-ids="pageState.selectedApplicant"
            :job-id="jobId"
            @closeModal="closeOnBoardingModal"
            @afterComplete="moveCandidatesToPipeline(pageState.moveToPipeline)"
        />

        <remote-interview
                v-if="pageState.showRemoteInterviewModal"
                :applicant-ids="pageState.selectedApplicant"
                :job-id="jobId"
                @remote_interview_closed="closeRemoteInterview()"
                @remote_interview_moved="movedToRemoteInterview()"
        >
        </remote-interview>

        <ModalEditPipeline v-if="showEditPipeline" @closeUpdateModal="closePipelineUpdateModal" @pipelineUpdated="pipelineUpdate" :pipeline="originalPipelines" :job="jobId"></ModalEditPipeline>

    </div>
</template>

<script>

import {canManagementJob, canOrganizeCandidate, canViewCandidate} from "../../../config/permission";

const ModalOnboarding = () => import("../../../components/modal/job/Onboarding");
const RemoteInterview = () => import("../../../components/modal/job/RemoteInterview");
const ModalEditPipeline = () => import("../../../components/modal/job/EditPipeline");

import {DEFAULT_PIPELINE, PERMISSIONS} from '../../../constants/enums';
import client from "../../../app/api/Client";
import {mapState, mapActions} from "vuex";
import {UPDATE_CANDIDATE_PIPELINE_ORDER, UPDATE_PAGE_STATE} from "../../../constants/action-type";


export default {
    name: "JobPipelineToggler",
    components: {
        ModalOnboarding,
        RemoteInterview,
        ModalEditPipeline,
    },
    data() {
        return  {
            isLoading: false,
            openMoveToStage: false,
            showEditPipeline: false,
        }
    },
    props: [
        'job',
    ],
    computed: {
        ...mapState(['company', 'user', 'pageState']),

        jobId() {
            return this.$route.params.jobId;
        },

        hasSelected() {
            if(this.pageState.showOnBoardingModal || this.pageState.showRemoteInterviewModal) {
                return false;
            }
            return this.pageState.selectedApplicant.length > 0;
        },
        originalPipelines() {
            return this.pageState.pipelines.filter(f => f.id !== 'rejected').filter(f => f.id !== 'selected')
        },
        pipelinesForMove() {
            if(_.isNull(this.pageState.activeStage)) {
                return [];
            } else {
                return _.filter(this.pageState.pipelines, (p) => p.id !== this.pageState.activeStage.id);
            }
        },
        canManagementJob() {
            return canManagementJob();
        },
        canViewCandidate() {
            return canViewCandidate();
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE, UPDATE_CANDIDATE_PIPELINE_ORDER]),

        showStage() {
            this.openMoveToStage = !this.openMoveToStage
        },

        hideMoveToStage() {
            this.openMoveToStage = false;
        },

        selected(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.SELECTED;
        },

        rejected(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.REJECTED;
        },

        isRemoteInterview(pipelineId) {
            return pipelineId === DEFAULT_PIPELINE.REMOTE_INTERVIEW;
        },

        editPipeline() {
            if (this.company.permission[PERMISSIONS.JOB_MANAGEMENT]) {
                this.showEditPipeline = true;
                const el = document.body;
                el.classList.add('modal-open');
            } else {
                this.$toast.error(this.$t(`Unauthorized access`));
            }
        },

        pipelineUpdate(value) {
            this.UPDATE_PAGE_STATE({'pipelines': value.data});
            this.closePipelineUpdateModal();
        },

        closePipelineUpdateModal() {
            this.showEditPipeline = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },

        async moveCandidatesToPipeline(pipeline) {
            if (this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                this.openMoveToStage = false;
                (this.pageState.selectedApplicant.length === 0) && this.$toast.error(this.$t(`Please select applicants first`));

                this.isLoading = true;

                try {

                    let  addCandidateData = {
                        applicants: this.pageState.selectedApplicant,
                        pipeline: pipeline.id,
                        last_applicant: this.pageState.defaultBoard
                    }

                    if(this.pageState.sortBy !== '') {
                        addCandidateData.last_applicant = true;
                    }

                    await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate`, addCandidateData);

                    if(this.pageState.defaultBoard) {
                        this.updateCandidateAfterMoveAndDrop(pipeline);
                    } else {
                        if(this.pageState.sortBy === '') {
                            let applicants = pipeline.applicants.map((applicant, order) => {
                                return {
                                    'applicant_id': applicant.id,
                                    order
                                }
                            });

                            let {data: {data: candidatePipelineOrders}} = await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate/re-order`, {
                                'applicants': applicants,
                                'pipeline_id': pipeline.id
                            });

                            this.UPDATE_PAGE_STATE({'candidatePipelineOrders' : candidatePipelineOrders});

                            this[UPDATE_CANDIDATE_PIPELINE_ORDER]();
                        }
                        this.UPDATE_PAGE_STATE({'selectedApplicant': []});
                    }

                } catch (error) {
                    this.$toast.error(this.$t(error.response.data.message));
                }

                this.isLoading = false;
            } else {
                this.$toast.error(this.$t(`Unauthorized access`));
            }
        },

        updateCandidateAfterMoveAndDrop(pipeline) {
            let selectedApplicant = this.pageState.selectedApplicant;

            let moveCandidates = _.filter(this.pageState.activeStage.applicants, (candidate) => {
                return _.indexOf(selectedApplicant, candidate.id) != -1;
            });

            let moveAfterCandidates = _.filter(this.pageState.activeStage.applicants, (candidate) => {
                return _.indexOf(selectedApplicant, candidate.id) == -1;
            });

            this.pageState.pipelines.map((p) => {
                if(p.id == this.pageState.activeStage.id) {
                    p.applicants = moveAfterCandidates;
                }
                if(p.id == pipeline.id) {
                    p.applicants = [...p.applicants , ...moveCandidates];
                }
                return p;
            });

            this.UPDATE_PAGE_STATE({'selectedApplicant': []});
        },

        candidateOnboard(pipeline) {
            this.openMoveToStage = false;
            if (this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                if (pipeline.id === DEFAULT_PIPELINE.SELECTED) {
                    this.UPDATE_PAGE_STATE({'showOnBoardingModal':true, 'moveToPipeline': pipeline});
                }
            } else {
                this.$toast.error(this.$t(`Unauthorized access`));
            }
        },

        selectedForRemoteInterview(pipeline) {
            if (this.pageState.selectedApplicant.length > 1) {
                this.$toast.error(this.$t(`Cannot select multiple candidates for remote interview at a time.`));
                return false;
            }
            this.UPDATE_PAGE_STATE({'showRemoteInterviewModal':true, 'moveToPipeline': pipeline});
        },
        closeOnBoardingModal() {
            if(this.pageState.defaultBoard) {
                this.resetSelectedCandidate();
            } else {
                this.resetMoveCandidate()
            }
            this.UPDATE_PAGE_STATE({'showOnBoardingModal':false, 'selectedApplicant': []});
        },
        closeRemoteInterview() {
            if(this.pageState.defaultBoard) {
                this.resetSelectedCandidate();
            } else {
                this.resetMoveCandidate()
            }
            this.UPDATE_PAGE_STATE({'showRemoteInterviewModal':false, 'selectedApplicant': []});
        },
        resetSelectedCandidate() {
            this.pageState.activeStage.applicants.map((a) => {
                a.isSelected = false;
                return a;
            });
        },
        resetMoveCandidate() {
            let moveToPipeline = this.pageState.moveToPipeline;
            let prevPipelineId = this.pageState.prevPipelineId;
            let applicantId = this.pageState.selectedApplicant[0];
            let oldDraggableIndex = this.pageState.oldDraggableIndex;

            let removeApplicant = _.find(moveToPipeline.applicants, (applicant) => {
                return applicant.id == applicantId;
            });

            let afterRemoveApplicants = _.filter(moveToPipeline.applicants, (applicant) => {
                return applicant.id != applicantId;
            });

            moveToPipeline.applicants = afterRemoveApplicants;
            this.pageState.pipelines.map((pipeline) => {
                if(pipeline.id == prevPipelineId) {
                    pipeline.applicants.splice(oldDraggableIndex, 0, removeApplicant);
                }
                return pipeline;
            });
        },
        async movedToRemoteInterview() {
            this.openMoveToStage = false;

            let pipeline = this.pageState.moveToPipeline;
            if(this.pageState.defaultBoard) {
                this.updateCandidateAfterMoveAndDrop(pipeline);
                this.closeRemoteInterview();
            } else {
                if(this.pageState.sortBy === '') {
                    let applicants = pipeline.applicants.map(({id}, order) => {
                        return {
                            'applicant_id': id,
                            order
                        }
                    });

                    let {data: {data: candidatePipelineOrders}}  = await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate/re-order`, {
                        'applicants': applicants,
                        'pipeline_id': pipeline.id
                    });

                    this.UPDATE_PAGE_STATE({'candidatePipelineOrders' : candidatePipelineOrders});

                    this[UPDATE_CANDIDATE_PIPELINE_ORDER]();
                }

                this.UPDATE_PAGE_STATE({'showRemoteInterviewModal':false, 'selectedApplicant': []});
            }
        },
    }
}
</script>


<style scoped>
    @media all and (max-width: 540px) {
        .pipeline-page-top-bar {
            font-size: 10px;
        }
        .pipeline-page-top-bar .back-button__text {
            font-size: 10px;
        }
        .pipeline-page-top-bar .section-title {
            font-size: 10px;
        }
    }


    .custom-topbar-sm-button {
        height: 32px;
        width: 32px;
        min-width: 32px;
        font-size: 0.875rem;
        padding: 10px;
        background: rgba(89,125,252,0.1);
        border-radius: 5px;
        margin-left: 10px;
    }

</style>
